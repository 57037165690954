import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                    <p>We've formed excellent working relationships and partnerships with our clients. Here's what they're saying about us.</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                        <p>Quality work and efficiency from Open Systems Ghana in the execution of the MOFA E-Extension platform. Glad AFC International supported them to attend CTA's conference on ICT4Ag in Kigali in Rwanda to showcase the project. A programme of the Ministry of Food and Agriculture of Ghana assisted by the German Government via the Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ)</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <h3>Christoph Arndt, MSc.</h3>
                                    <span>Market Oriented Agriculture Programme Leader of the Team of AFC Consultants International</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Open Systems Ghana is always responsive to any question I had or ticket request I submitted. They provided us with a 5 year project support for the Youth Inclusive Entrepreneurial Development initiative for Employment (YIEDIE) Participant Management Database System throughout the project lifecycle. They are consummate professionals and will highly recommend them.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <h3>Samuel Seyram Anokye (BSc, MBA-MIS)</h3>
                                    <span>Information Technology (IT) Coordinator at Global Communities USAID/W4H Program</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>We have worked with Open Systems Ghana to develop a website and the Economic Transformation Barometer Survey Tool for our Economic Policy Competence Center (EPCC). Throughout our engagement, Open Systems demonstrated strong expertise on these assignments from thought to finish with high professionalism and attention to detail. We will not hesitate to recommend them to organizations needing their services.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client3} alt="testimonial" />
                                <div className="title">
                                    <h3>Ebow Mensah, Programmes Director</h3>
                                    <span>The Friedrich Ebert Stiftung Ghana</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}

                <div className="testimonials-view-btn text-center">
                    {/* <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i> 
                        Check Out All Reviews <span></span>
                    </Link> */}
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;