import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"
import team1 from "../../assets/images/team/team1.jpg"
import team2 from "../../assets/images/team/team2.jpg"
import team3 from "../../assets/images/team/team3.jpg"
import team4 from "../../assets/images/team/team4.jpg"
import team5 from "../../assets/images/team/team5.jpg"
import team6 from "../../assets/images/team/team6.jpg"
import team7 from "../../assets/images/team/team7.jpg"
import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplay: true,
  margin: 30,
  slideBy: 1,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    368: {
      items: 2,
    },
    568: {
      items: 3,
    },
    768: {
      items: 4,
    },
    968: {
      items: 5,
    },
  },
}

const TeamMember = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <section className="scientist-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="team" />
            Team Members
          </span>
          <h2>Our Awesome Team</h2>
          <p>Our culture is comfortable, flexible, and dynamic. We are committed to your satisfaction and driven to exceed your expectations.</p>
      
        </div>
      </div>

      <div className="container">
        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team1} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
              <h3>Jon Antwi</h3>
              <span>CEO & Founder</span>
              </div>
            </div>

            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team2} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Daniel Nii Teiko Aryee</h3>
                <span>UX/UI Designer</span>
              </div>
            </div>

            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team3} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Julian Smith</h3>
                <span>Senior Developer</span>
              </div>
            </div>

            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team4} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Emmanuel Oteng Wilson</h3>
                <span>Senior Developer</span>
              </div>
            </div>

            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team5} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Ghaffaru Mudashiru</h3>
                <span>Senior Developer</span>
              </div>
            </div>

            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team6} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Dennis Gyamfi</h3>
                <span>Senior Developer</span>
              </div>
            </div>

            <div className="single-scientist-item-box">
              <div className="image">
                <img src={team7} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>David Ayitey</h3>
                <span>Administration</span>
              </div>
            </div>

            {/* <div className="single-scientist-item-box">
              <div className="image">
                <img src={team4} alt="team" />

                <ul className="social">
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>4</h3>
                <span>Support</span>
              </div>
            </div> */}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </section>
  )
}

export default TeamMember
