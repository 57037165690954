import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import project1 from '../../assets/images/projects/project1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import project3 from '../../assets/images/projects/project3.jpg'
import project4 from '../../assets/images/projects/project4.jpg'
import project5 from '../../assets/images/projects/project5.jpg'
import project6 from '../../assets/images/projects/project6.jpg'

const RecentProjects = () => {
    return (
        <section className="projects-area bg-color pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Recent Projects
                    </span>
                    <h2>Check Some Of Our Recent Work</h2>
                    <p>We've worked on loads of amazing projects for SMEs with five to 200 staff, mid-tier Government departments and Not-For-Profit agencies and NGOs. </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project1} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                   {/*  <Link to="/case-studies-details">
                                        Movie Recommendation
                                    </Link> */}
                                </h3>
                                <span>The Friedrich-Ebert-Stiftung Ghana Economic Transformation Baromter Project</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project2} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                   {/*  <Link to="/case-studies-details">
                                        Customer Segmentation
                                    </Link> */}
                                </h3>
                                <span>TwinCity Smartsol Project for STMA financed by Global Communities</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project3} alt="project" />

                               {/*  <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                   {/*  <Link to="/case-studies-details">
                                        Data Analysis
                                    </Link> */}
                                </h3>
                                <span>MOFA Extension Platform Project financed by GIZ / AFC International</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project4} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    {/* <Link to="/case-studies-details">
                                        Detection Project
                                    </Link> */}
                                </h3>
                                <span>NavmanGPS Tracker Project</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={project5} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                   {/*  <Link to="/case-studies-details">
                                        Data Scientist
                                    </Link> */}
                                </h3>
                                <span>YIEDIE (Youth Inclusive Entrepreneurial Development Initiative for Employment) Project for Global Communities.</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project6} alt="project" />

                               {/*  <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    {/* <Link to="/case-studies-details">
                                        Benefits Research
                                    </Link> */}
                                </h3>
                                <span>WEKADA Motorcycle, Tricycle Transportation & Delivery </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecentProjects;