import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Design & Development</h2>
                                    <p>Delivering to mobile first is beyond simply smart, it is vital in an ever increasingly device centric world. Our work is geared up for the market demands of the mobile economy.</p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            UX/UI Design
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Database Development
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Web Development
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Apps Development
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Laravel Development
                                        </li>
                                        
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            E-commerce Design
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Maintenance Services
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Custom Solutions
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}

            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Cloud Hosting Services</h2>
                                    <p>We generally recommend that clients procure a managed hosting solution. Managed hosting can be for a dedicated or virtualized server environment. By selecting managed hosting clients are able to rely on the hosting provider to manage the network, hardware, and software stack infrastructure 24/7/365 as part of the hosting costs.</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloud Databases
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Website Hosting
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            File Storage
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Digital Currencies
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            File Backups
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Remote Desktop
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Email Servers
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Hybrid Cloud
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}
        </React.Fragment>
    )
}

export default OurServices;