import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>We Are Different From Others Why You Should Choose Us</h2>
                    <p>Our core expertise is in 'Full-Stack' Mobile, 'Front-End' and 'Back-end' technically driven Web & Desktop applications.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-web"></i>
                            </div>
                            <h3>
                                <Link to="#">
                                    Enterprise Software Development
                                </Link>
                            </h3>
                            <p>Developed based on an environment within an organization. It is also known as Custom Software development, and as a result, you usually get useful tools to develop the business.</p>

                           {/*  <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                Mobile App Development
                                </Link>
                            </h3>

                            <p>We deliver feature-rich, easy-to-use mobile solutions for all major platforms. Our mobile app development services are designed to deliver cutting-edge, bespoke solutions for your unique business requirements.</p>
                            
                           {/*  <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    UI / UX Designing Services
                                </Link>
                            </h3> 

                            <p>Specialized in creating beautiful and smooth UI/UX designs that provide better user experience by incorporating effective collaboration for better results. We push brands to define their image with UI design services and make an enduring impact that drives and inspires users.</p>
                            
                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;