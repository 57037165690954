import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Our Features
                    </span>

                    <h2>We’re Here To Help</h2>
                    <p>We pride ourself on being able to provide our clients with responsive service and  high level of support to their satisfaction.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>CMS Development</h3>
                            <p>We utilize the latest technologies and industry trends to deliver Scalable and cutting-edge CMS-based web development services.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Cybersecurity</h3>
                            <p>We can help businesses to protect their devices (smartphones, laptops, tablets and computers), and the services they access - both online and at work - from theft or damage.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>CRM Development</h3>
                            <p>We create the kind of CRM technology tools that provide direct access to customer information, which is essential for evaluating customer service processes.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Backend Framework Development</h3>
                            <p>Backend web development frameworks are nothing but the libraries of server-side frameworks that aid to develop the structure of any site.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Managed IT Services</h3>
                            <p>We support businesses to overcome IT Infrastructure challenges by remote and onsite IT helpdesk support, round the clock Remote Infrastructure Management, and Cloud Consulting.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>Internet Marketing & SEO</h3>
                            <p>Success on the internet largely depends on how much you’re visible to the people who are looking for or need your products or services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures